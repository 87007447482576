import React, { useState, useEffect } from "react";
import logomain from "../assets/images/logo.png";
import pdf from "../assets/images/pdf.png";
import { Link } from "react-router-dom";
import Support from "../components/Support";
import { Pencil } from "bootstrap-icons-react";
import axios from "axios";
import { baseUrl } from "../config";

const SchoolModule = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [editIsDisabled, editsetIsDisabled] = useState(false);
  const [update, setUpdate] = useState(false);
  const [formData, setFormData] = useState({
    logo: "",
    minutesAttachment: "",
    schoolcode: "",
    schoolName: "",
    userId: "",
    password: "",
    contactPersons: [],
    devTeamMember: [],
    minutes: [],
    workFlowDate: "",
    workOrder: "",
    school: false,
    highlight: false,
    event: false,
    circular: false,
    notification: false,
    birthday: false,
    tc: false,
    slider: false,
    videoGallery: false,
    topper: false,
    message: false,
    onlineRegistration: false,
    testimonial: false,
    gallery: false,
    news: false,
    faculty: false,
    activity: false,
    achivement: false,
    kidsCorner: false,
    newsletter: false,
    syllabus: false,
    datesheet: false,
    magazine: false,
    calender: false,
    dipscoop: false,
    loquacity: false,
    form: false,
    useFullLinks: false,
    chairman:false,
    president: false,
    vicePrincipal: false,
    principal: false,
    bishop: false,
    manager: false,
    director: false,
    brother: false,
    newsUrl: false,
    newsImage: false,
    journey: false,
    playlist: false,
    mediaGallery: false,
    homework: false,
    reading: false,
    thought: false,
    JPICGallery: false,
    headboy: false,
    headgirl: false,
    TvGallery: false,
    admission: false,
    booklist: false,
    blog: false,
    urlTitle: false,
  });

  const addContactPerson = () => {
    setFormData((prevState) => ({
      ...prevState,
      contactPersons: [
        ...prevState.contactPersons,
        { personName: "", designation: "", phone: "", email: "" },
      ],
    }));
  };

  const handleContactPersonChange = (e, index) => {
    const { name, value } = e.target;
    const updatedContactPersons = [...formData.contactPersons];
    updatedContactPersons[index][name] = value;
    setFormData({ ...formData, contactPersons: updatedContactPersons });
  };

  const removeContactPerson = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      contactPersons: prevState.contactPersons.filter((_, i) => i !== index),
    }));
  };

  const addDevPerson = () => {
    setFormData((prevState) => ({
      ...prevState,
      devTeamMember: [
        ...prevState.devTeamMember,
        { devName: "", devDesignation: "", devId: "", devEmail: "" },
      ],
    }));
  };

  const handleDevPersonChange = (e, index) => {
    const { name, value } = e.target;
    const updatedDevPersons = [...formData.devTeamMember];
    updatedDevPersons[index][name] = value;
    setFormData({ ...formData, devTeamMember: updatedDevPersons });
  };

  const removeDevPerson = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      devTeamMember: prevState.devTeamMember.filter((_, i) => i !== index),
    }));
  };

  const addMinutesPerson = () => {
    setFormData((prevState) => ({
      ...prevState,
      minutes: [
        ...prevState.minutes,
        { schoolContactPerson: "", schoolDate: "", minutesAttachment: null },
      ],
    }));
  };

  const handleMinutesChange = (e, index) => {
    const { name, value, files } = e.target;
    const updatedMinutes = [...formData.minutes];
    if (name === "minutesAttachment") {
      updatedMinutes[index][name] = files[0];
    } else {
      updatedMinutes[index][name] = value;
    }
    setFormData({ ...formData, minutes: updatedMinutes });
  };

  const removeMinutesPerson = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      minutes: prevState.minutes.filter((_, i) => i !== index),
    }));
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedImage(file);
      setFormData((prevFormData) => ({
        ...prevFormData,
        logo: file,
      }));
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage(null);
    setFormData((prevFormData) => ({
      ...prevFormData,
      logo: "",
    }));
  };

  const handleEditClick = () => {
    setIsDisabled(false);
    editsetIsDisabled(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    console.log(checked);
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    try {
      const newFormData = new FormData();
      for (const key in formData) {
        if (Array.isArray(formData[key])) {
          formData[key].forEach((item, index) => {
            newFormData.append(`id`, formData?._id);
            if (item instanceof File) {
              newFormData.append(`${key}[${index}]`, item);
            } else if (item && typeof item === "object") {
              for (const subKey in item) {
                newFormData.append(`${key}[${index}][${subKey}]`, item[subKey]);
              }
            } else {
              newFormData.append(`${key}[${index}]`, item);
            }
          });
        } else {
          if (key === "logo" && !selectedImage) {
            newFormData.append(key, formData.logo);
          } else if (formData[key] instanceof File) {
            newFormData.append(key, formData[key]);
          } else {
            newFormData.append(key, formData[key]);
          }
        }
      }

      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get("id");

      console.log(formData);

      const response = await axios.put(
        `${baseUrl}/api/schoolAdmin`,
        newFormData
      );

      console.log("Updated data: ", response.data);
      setIsDisabled(true);
      setUpdate(true);
      editsetIsDisabled(false);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/schoolAdmin?id=${id}`);
        const data = response.data;

        // Convert string booleans to actual booleans
        const convertedData = {
          ...data,
          school: data.school === "true",
          highlight: data.highlight === "true",
          event: data.event === "true",
          circular: data.circular === "true",
          notification: data.notification === "true",
          birthday: data.birthday === "true",
          tc: data.tc === "true",
          slider: data.slider === "true",
          videoGallery: data.videoGallery === "true",
          topper: data.topper === "true",
          message: data.message === "true",
          onlineRegistration: data.onlineRegistration === "true",
          testimonial: data.testimonial === "true",
          gallery: data.gallery === "true",
          news: data.news === "true",
          faculty: data.faculty === "true",
          activity: data.activity === "true",
          achivement: data.achivement === "true",
          kidsCorner: data.kidsCorner === "true",
          newsletter: data.newsletter === "true",
          syllabus: data.syllabus === "true",
          datesheet: data.datesheet === "true",
          magazine: data.magazine === "true",
          calender: data.calender === "true",
          dipscoop: data.dipscoop === "true",
          loquacity: data.loquacity === "true",
          form: data.form === "true",
          useFullLinks: data.useFullLinks === "true",
          chairman:  data.chairman === "true",
          president: data.president === "true",
    vicePrincipal: data.vicePrincipal === "true",
    principal: data.principal === "true",
    bishop: data.bishop === "true",
    manager: data.manager === "true",
    director: data.director === "true",
    brother: data.brother === "true",
    newsUrl: data.newsUrl === "true",
    newsImage: data.newsImage === "true",
          journey: data.journey === "true",
          playlist: data.playlist === "true",
          mediaGallery: data.mediaGallery === "true",
          homework: data.homework === "true",
          reading: data.reading === "true",
          thought: data.thought === "true",
          JPICGallery: data.JPICGallery === "true",
          TvGallery: data.TvGallery === "true",
          admission: data.admission === "true",
          booklist: data.booklist === "true",
          headboy: data.headboy === "true",
          headgirl: data.headgirl === "true",
          blog: data.blog === "true",
          urlTitle: data.urlTitle === "true",
        };

        setUserData(convertedData);
        setFormData(convertedData);
        console.log("Fetched data: ", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [update]);

  console.log(formData);

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className={`userPage ${!isDisabled ? "active" : ""}`}>
        <div className="sub-user">
          <Support />
          <div className="logo">
            <img src={logomain} alt="Logo" />
          </div>
          <div className="backArrow">
            <Link to="/adduser">
              &#8592; <span>Back</span>
            </Link>
            <div
              className="edit"
              onClick={handleEditClick}
              disabled={editIsDisabled}
            >
              <Pencil />
            </div>
          </div>

          <div className="form show-details">
            {formData && (
              <>
                <div className="d-flex wid-100">
                  <div className="imageUpload">
                    <div className="uploadImageBlock">
                      <div className="icon">
                        <Pencil />
                      </div>
                      <input
                        accept="image/*"
                        name="logo"
                        type="file"
                        disabled={isDisabled}
                        onChange={imageChange}
                      />
                      {selectedImage ? (
                        <div>
                          <img
                            src={
                              selectedImage instanceof File
                                ? URL.createObjectURL(selectedImage)
                                : `${baseUrl}/api/image?url=${selectedImage}`
                            }
                            alt="Thumb"
                          />
                          <button onClick={removeSelectedImage}>
                            Remove This Image
                          </button>
                        </div>
                      ) : (
                        formData.logo && (
                          <img
                            src={`${baseUrl}/api/image?url=${formData.logo}`}
                            alt="School Logo"
                          />
                        )
                      )}
                    </div>
                  </div>
                  <div className="input-feild wid-100">
                    <div>
                      <label>School Code: </label>
                      <input
                        type="text"
                        name="schoolcode"
                        value={formData.schoolcode}
                        disabled={isDisabled}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label>School Name: </label>
                      <input
                        type="text"
                        name="schoolName"
                        value={formData.schoolName}
                        disabled={isDisabled}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label>User Id:</label>
                      <input
                        type="text"
                        name="userId"
                        value={formData.userId}
                        disabled={isDisabled}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label>Password:</label>
                      <input
                        type="text"
                        name="password"
                        value={formData.password}
                        disabled={isDisabled}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="d-flex justify-space-between">
                    School Contact Persons Details
                    <div className="addFeild" onClick={addContactPerson}>
                      <div className="dark-btn">+</div>
                    </div>
                  </h3>
                  {formData.contactPersons.map((contactPerson, index) => (
                    <div className="input-feild wid-100" key={index}>
                      <div className="wid-4">
                        <label>Person Name:</label>
                        <input
                          type="text"
                          name="personName"
                          value={contactPerson.personName}
                          disabled={isDisabled}
                          onChange={(e) => handleContactPersonChange(e, index)}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Designation:</label>
                        <input
                          type="text"
                          name="designation"
                          value={contactPerson.designation}
                          disabled={isDisabled}
                          onChange={(e) => handleContactPersonChange(e, index)}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Phone Number:</label>
                        <input
                          type="text"
                          name="phone"
                          value={contactPerson.phone}
                          disabled={isDisabled}
                          onChange={(e) => handleContactPersonChange(e, index)}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Email ID:</label>
                        <input
                          type="text"
                          name="email"
                          value={contactPerson.email}
                          disabled={isDisabled}
                          onChange={(e) => handleContactPersonChange(e, index)}
                        />
                      </div>
                      <div
                        className="removeBtn"
                        onClick={() => removeContactPerson(index)}
                      >
                        ✖
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <h3 className="d-flex justify-space-between">
                    Development Team Member
                    <div className="addFeild" onClick={addDevPerson}>
                      <div className="dark-btn">+</div>
                    </div>
                  </h3>
                  {formData.devTeamMember.map((devPerson, index) => (
                    <div className="input-feild wid-100" key={index}>
                      <div className="wid-4">
                        <label>Person Name: </label>
                        <input
                          type="text"
                          name="devName"
                          value={devPerson.devName}
                          disabled={isDisabled}
                          onChange={(e) => handleDevPersonChange(e, index)}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Designation: </label>
                        <input
                          type="text"
                          name="devDesignation"
                          value={devPerson.devDesignation}
                          disabled={isDisabled}
                          onChange={(e) => handleDevPersonChange(e, index)}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Employee Id: </label>
                        <input
                          type="text"
                          name="devId"
                          value={devPerson.devId}
                          disabled={isDisabled}
                          onChange={(e) => handleDevPersonChange(e, index)}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Email ID: </label>
                        <input
                          type="text"
                          name="devEmail"
                          value={devPerson.devEmail}
                          disabled={isDisabled}
                          onChange={(e) => handleDevPersonChange(e, index)}
                        />
                      </div>
                      <div
                        className="removeBtn"
                        onClick={() => removeDevPerson(index)}
                      >
                        ✖
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <h3>Work Flow Details</h3>
                  <div className="input-feild wid-100">
                    <div>
                      <label>Start Date:</label>
                      <input
                        type="text"
                        name="workFlowDate"
                        value={formData.workFlowDate}
                        disabled={isDisabled}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label>Work Order:</label>
                      <input
                        type="file"
                        name="workOrder"
                        disabled={isDisabled}
                        onChange={handleChange}
                      />
                      <a
                        href={`${baseUrl}/api/image?url=${formData.workOrder}`}
                      >
                        <img src={pdf} alt="PDF" />
                      </a>
                    </div>
                  </div>
                  <h3 className="d-flex justify-space-between">
                    Minutes{" "}
                    <div className="addFeild" onClick={addMinutesPerson}>
                      <div className="dark-btn">+</div>
                    </div>
                  </h3>
                  {formData.minutes.map((minutes, index) => (
                    <div className="input-feild wid-100" key={index}>
                      <div className="wid-3">
                        <label>Person Name:</label>
                        <input
                          type="text"
                          name="schoolContactPerson"
                          value={minutes.schoolContactPerson}
                          disabled={isDisabled}
                          onChange={(e) => handleMinutesChange(e, index)}
                        />
                      </div>
                      <div className="wid-3">
                        <label>Date:</label>
                        <input
                          type="text"
                          name="schoolDate"
                          value={minutes.schoolDate}
                          disabled={isDisabled}
                          onChange={(e) => handleMinutesChange(e, index)}
                        />
                      </div>
                      <div className="wid-3">
                        <label>Attach Document: </label>
                        <input
                          type="file"
                          name="minutesAttachment"
                          disabled={isDisabled}
                          onChange={(e) => handleMinutesChange(e, index)}
                        />
                        <a
                          href={`${baseUrl}/api/image?url=${minutes.minutesAttachment}`}
                        >
                          <img src={pdf} alt="PDF" />
                        </a>
                      </div>
                      <div
                        className="removeBtn"
                        onClick={() => removeMinutesPerson(index)}
                      >
                        ✖
                      </div>
                    </div>
                  ))}
                </div>

                <div className="modules">
                  <h2>Select Modules</h2>
                  <ul>
                    {[
                      "school",
                      "highlight",
                      "event",
                      "circular",
                      "notification",
                      "birthday",
                      "tc",
                      "slider",
                      "videoGallery",
                      "topper",
                      "message",
                      "onlineRegistration",
                      "testimonial",
                      "gallery",
                      "news",
                      "faculty",
                      "activity",
                      "achivement",
                      "kidsCorner",
                      "newsletter",
                      "syllabus",
                      "datesheet",
                      "magazine",
                      "calender",
                      "form",
                      "useFullLinks",
                      "chairman",
                      "president",
                      "vicePrincipal",
                      "principal",
                      "manager",
                      "director",
                      "newsUrl",
                      "newsImage",
                      'mediaGallery',
                      'homework',
                     
                    ].map((module) => (
                      <li key={module}>
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            name={module}
                            checked={formData[module]}
                            disabled={isDisabled}
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {module.charAt(0).toUpperCase() + module.slice(1)}
                      </li>
                    ))}
                  </ul>
                 
                </div>
                <div className="modules">
                  <h2>Special Modules</h2>
                  <ul>
                    {[
                      "dipscoop",
                      "loquacity",
                      "bishop",
                      "brother",
                      "journey",
                      "playlist",
                      "reading",
                      "thought",
                      "JPICGallery",
                      "headboy",
                      "headgirl",
                      "TvGallery",
                      "admission",
                      "booklist",
                      "blog",
                      "urlTitle",
                    ].map((module) => (
                      <li key={module}>
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            name={module}
                            checked={formData[module]}
                            disabled={isDisabled}
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {module.charAt(0).toUpperCase() + module.slice(1)}
                      </li>
                    ))}
                  </ul>
                  <input type="submit" value="Save" onClick={handleSaveClick} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolModule;
